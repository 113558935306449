/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { CustomLink } from "../custom-link";
import { useSelector } from "react-redux";

export const Monogram = (props) => {
  const qtState = useSelector((state) => state || {});
  const {
    logo = "",
    darkLogo = "",
    navigateFooterLogo = "",
    logoUrl = "",
  } = get(qtState, ["qt", "config", "pagebuilder-config", "footer"], {});
  const getDarkLogo = darkLogo ? darkLogo : logo;
  const enableDarkMode = get(qtState, ["header", "isDarkModeEnabled"], false);
  const publisherName = get(qtState, ["qt", "config", "publisher-name"], "");

  const currentHostUrl = get(qtState, ["qt", "currentHostUrl"], "/");
  const navigateUrl = navigateFooterLogo === "customUrl" ? logoUrl : currentHostUrl;

  return (
    <div>
      <CustomLink href={navigateUrl}>
        {enableDarkMode ? (
          <div>
            <img className="logo" src={getDarkLogo} alt={publisherName} loading="lazy" />
          </div>
        ) : (
          <img className="logo" src={logo} alt={publisherName} loading="lazy" />
        )}
      </CustomLink>
    </div>
  );
};

Monogram.propTypes = {
  publisherName: PropTypes.string,
  logoUrl: PropTypes.string,
};
