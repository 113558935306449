/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
i */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import { SocialFollow } from "../../basic/social-follow/social-follow";
import { getTextContrast } from "../../../utils";

const HeaderSocialFollowBase = ({
  socialLinks,
  headerSocialFollowStyle,
  headerSecondaryBgColor,
  headerPrimaryBgColor,
  headerTheme,
  headerDarkPrimaryBgColor,
  headerDarkSecondaryBgColor,
  enableDarkMode,
}) => {
  const isSingleLayer = headerTheme.includes("single-layer");
  let socialFollowBgColor = "";

  if (enableDarkMode) {
    isSingleLayer
      ? (socialFollowBgColor = headerDarkPrimaryBgColor)
      : (socialFollowBgColor = headerDarkSecondaryBgColor);
  } else {
    isSingleLayer ? (socialFollowBgColor = headerPrimaryBgColor) : (socialFollowBgColor = headerSecondaryBgColor);
  }

  const headerClass = isSingleLayer ? "single-layer-header" : "header";

  const getCustomCssClasses = () => {
    switch (headerTheme) {
      case "header_4":
        return "header-four";
      case "single-layer-header_4":
        return "single-layer-header-four";
      case "single-layer-header_5":
        return "single-layer-header-five";
      default:
        return "";
    }
  };

  return (
    <SocialFollow
      hasBackground={true}
      contrastAgainstBg={getTextContrast(socialFollowBgColor)}
      socialStyle={headerSocialFollowStyle}
      socialLinks={socialLinks}
      color="#000"
      customCssClasses={[headerClass, getCustomCssClasses()].join(" ")}
    />
  );
};

const mapStateToProps = (state) => {
  const pageBuilderHeader = get(state, ["qt", "config", "pagebuilder-config", "header"], {});
  return {
    socialLinks: get(state, ["qt", "config", "social-links"], {}),
    headerPrimaryBgColor: get(pageBuilderHeader, ["primaryBackgroundColor"], "#ffffff"),
    headerDarkPrimaryBgColor: get(pageBuilderHeader, ["darkPrimaryBackgroundColor"], "#292929"),
    headerSecondaryBgColor: get(pageBuilderHeader, ["secondaryBackgroundColor"], "#ffffff"),
    headerDarkSecondaryBgColor: get(pageBuilderHeader, ["darkSecondaryBackgroundColor"], "#303030"),
    enableDarkMode: get(state, ["header", "isDarkModeEnabled"], false),
    headerSocialFollowStyle: get(pageBuilderHeader, ["socialFollowStyle"], "circular-color-svg"),
    headerTheme: get(pageBuilderHeader, ["headerStyle"], "header_1"),
  };
};

HeaderSocialFollowBase.propTypes = {
  socialLinks: PropTypes.object,
  socialFollowStyle: PropTypes.string,
  componentType: PropTypes.string,
  headerTheme: PropTypes.string,
  headerSocialFollowStyle: PropTypes.string,
  headerSecondaryBgColor: PropTypes.string,
  headerPrimaryBgColor: PropTypes.string,
};

export const HeaderSocialFollow = connect(mapStateToProps)(HeaderSocialFollowBase);
