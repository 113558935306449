/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { CustomLink } from "../custom-link";
import { MenuLink } from "../menu-link";
import { HeaderLogo } from "./header-logo";

import "./header-helper-components.m.css";

export const getMenuData = (menu, menuGroup, domainSlug = "") => {
  const filteredMenu = domainSlug
    ? menu.filter((item) => item["menu-group-slug"] === `${menuGroup}-${domainSlug}`)
    : menu.filter((item) => item["menu-group-slug"] === menuGroup);

  if (menuGroup.includes("sidebar-menu")) {
    if (filteredMenu.length === 0) return getMenuData(menu, "default");
  }
  return filteredMenu;
};

export const CtaWrapper = (props) => {
  const { customCssClasses = "", isSingleLayer, enableAccesstype, localizedElementsData = {}, socialFollowId = "" } = props;
  const { buttonLabels = {} } = localizedElementsData;
  const { subscribe: localizedSubscribe } = buttonLabels;

  const headerClass = isSingleLayer ? "single-layer-header" : "header";
  const cssClasses = [headerClass, customCssClasses].join(" ");

  return (
    <>
      {enableAccesstype && (
        <CustomLink className={cssClasses} href="/subscription" data-test-id="header-subscribe">
          {localizedSubscribe || "Subscribe"}
        </CustomLink>
      )}
      <div id={socialFollowId || "header-social-follow"} />
    </>
  );
};
CtaWrapper.propTypes = {
  customCssClasses: PropTypes.string,
  isSingleLayer: PropTypes.bool,
  enableAccesstype: PropTypes.bool,
  localizedElementsData: PropTypes.shape({
    buttonLabels: PropTypes.shape({
      subscribe: PropTypes.string,
    }),
  }),
};

export const LogoWrapper = (props) => {
  // Setting sketchesHost as the logo handler for now for LogoWrapper and Monogram and Footer.
  // Once subdomain changes are also in place, we may need to check this
  // how this going to playout.
  return (
    <div className="logo-wrapper">
      <CustomLink
        href={props.logoUrl}
        callback={() => {
          props.closeAll();
          typeof props.windowScroll === "function" && props.windowScroll();
        }}
      >
        {props.type === "header-logo-mobile" ? <div id="header-logo-mobile">
          <HeaderLogo />
        </div> : <div id="header-logo">
            <HeaderLogo />
          </div>}
      </CustomLink>
    </div>
  );
};


LogoWrapper.propTypes = {
  windowScroll: PropTypes.func,
  closeAll: PropTypes.func,
  headerSecondaryLogo: PropTypes.string,
  logoUrl: PropTypes.string,
};

export const getMenuItem = (menuItem, isSidebarMenu = false) => {
  const dispatch = useDispatch();
  const closeMenu = () => dispatch({ type: "CLOSE_ALL" });
  const menuType = isSidebarMenu || menuItem["item-type"] === "placeholder" || !menuItem.url || !menuItem.completeUrl;
  return menuType ? (
    <span className="title" data-testid="menu-title">
      {menuItem.title}
    </span>
  ) : (
      <MenuLink item={menuItem} callback={closeMenu} />
    );
};
