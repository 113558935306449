/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect, useRef } from "react";

export const HeaderSticky = () => {
  const stickyRef = useRef(null);

  const makeSticky = entries => {
    const fixedClassName = "sticky";
    const headerClassList = document.getElementById("header") && document.getElementById("header").classList;
    const stickyClassList =
      document.getElementById("header-sticky") && document.getElementById("header-sticky").classList;
    if (headerClassList) {
      entries.forEach(entry => {
        if (entry.rootBounds) {
          if (entry.isIntersecting) {
            headerClassList.remove(fixedClassName);
            stickyClassList.remove("sticky-placeholder");
          } else {
            headerClassList.add(fixedClassName);
            stickyClassList.add("sticky-placeholder");
          }
        }
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(makeSticky, {});
    if (stickyRef.current) {
      observer.observe(stickyRef.current);
    }
    return () => {
      if (stickyRef.current) {
        observer.unobserve(stickyRef.current);
      }
    };
  }, []);

  return <div ref={stickyRef} />;
};
