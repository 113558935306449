/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import "./user-profile.m.css";
import { SvgHandler } from "../svg-handler/svg-handler";
import defaultAuthorIcon from "../../../assets/icons/user-icon.svg";
import { UserData } from "./user-profile-data";
import { checkIfSingleSignOn, navigateToAuthDomain } from "../sso-components/sso-utils";
import get from "lodash/get";

class UserProfile extends React.Component {
  componentDidMount = () => {
    global && global.addEventListener("message", this.metypeEventHandler);
  };

  metypeEventHandler = (event) => {
    const action = get(event, ["data", "action"]);
    if (action === "metype_login") {
      this.handleOnclick();
    }
  };

  toggleForm = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.props.openLoginTemplate();
  };

  handleOnclick = (event) => {
    const bridgeKeeperClientId = get(this.props, ["sso", "bridgeKeeperClientId"]);
    checkIfSingleSignOn(this.props.sso) ? navigateToAuthDomain(bridgeKeeperClientId) : this.toggleForm(event);
  };

  render() {
    const { member, customCssClasses = "", localizedSignIn } = this.props;
    return (
      <>
        {!member || !member["verification-status"] ? (
          <div onClick={this.handleOnclick} styleName={`sign-btn ${customCssClasses}`} data-testid="sign-in-btn">
            <span styleName="white-space" data-test-id="user-profile" className="sign-in-btn">
              <div styleName="sign-in-svg" className="sign-in-svg" data-testid="sign-in-svg">
                <SvgHandler
                  xlinkHref={defaultAuthorIcon}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  svgWrapperClazzName="svg-wrapper"
                />
              </div>
              <div styleName="sign-in-btn" className="sign-in-button" data-testid="sign-in-button">
                {localizedSignIn || "Sign In"}
              </div>
            </span>
          </div>
        ) : (
          <UserData customCssClasses={customCssClasses} />
        )}
      </>
    );
  }
}
UserProfile.propTypes = {
  member: PropTypes.object,
  openLoginTemplate: PropTypes.func,
  logout: PropTypes.func,
  customCssClasses: PropTypes.string,
  localizedSignIn: PropTypes.string,
  sso: PropTypes.shape({
    enableSingleSignOn: PropTypes.bool,
    bridgeKeeperClientId: PropTypes.string,
  }),
};

export { UserProfile };
