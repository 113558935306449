/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { PAGE_TYPE } from "./constants";
import { withStoryPageContent } from "./component-bundles/bundling-utils";
const { pickComponent, getChunkName } = pickComponentHelper(
  {
    [PAGE_TYPE.HOME_PAGE]: { chunk: "home", component: "HomePage" },
    [PAGE_TYPE.SECTION_PAGE]: { chunk: "list", component: "SectionPage" },
    [PAGE_TYPE.TAG_PAGE]: { chunk: "list", component: "TagPage" },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: "list", component: "SearchPage" },
    [PAGE_TYPE.STORY_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.STORY_PREVIEW]: {
      chunk: "story",
      component: "StoryPagePreview",
    },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: {
      chunk: "story",
      component: "StoryPublicPagePreview",
    },
    [PAGE_TYPE.HOME_PREVIEW]: { chunk: "home", component: "HomePagePreview" },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: "list", component: "AuthorPage" },
    [PAGE_TYPE.COLLECTION_PAGE]: { chunk: "list", component: "CollectionPage" },
    [PAGE_TYPE.PB_STORY_PREVIEW_PAGE]: {
      chunk: "story",
      component: "StoryPage",
    },
    [PAGE_TYPE.PB_COLLECTION_PREVIEW_PAGE]: {
      chunk: "home",
      component: "PbPreview",
    },
    [PAGE_TYPE.PB_SECTION_PREVIEW_PAGE]: {
      chunk: "list",
      component: "SectionPage",
    },
    [PAGE_TYPE.SUBSCRIPTION]: {
      chunk: "subscription",
      component: "Subscription",
    },
    [PAGE_TYPE.USER_DETAILS]: {
      chunk: "subscription",
      component: "UserDetails",
    },
    [PAGE_TYPE.USER_LOGIN]: {
      chunk: "subscription",
      component: "UserLogin",
    },
    [PAGE_TYPE.AUTHORS_PAGE]: { chunk: "list", component: "AuthorsPage" },
    [PAGE_TYPE.PB_AUTHORS_PREVIEW_PAGE]: { chunk: "list", component: "AuthorsPage" },
    [PAGE_TYPE.ISSUE_LANDING_PAGE]: { chunk: "list", component: "IssueLandingPage" },
    [PAGE_TYPE.MAGAZINE_LANDING_PAGE]: { chunk: "list", component: "MagazineLandingPage" },
    [PAGE_TYPE.MAGAZINE_ARCHIVE_PAGE]: { chunk: "list", component: "MagazineArchivePage" },
    [PAGE_TYPE.PB_AUTHOR_PREVIEW_PAGE]: { chunk: "list", component: "AuthorPage" },
    [PAGE_TYPE.PB_TAG_PREVIEW_PAGE]: { chunk: "list", component: "TagPage" },
    [PAGE_TYPE.PB_MAGAZINE_ISSUE_PREVIEW_PAGE]: { chunk: "list", component: "IssueLandingPage" },
    [PAGE_TYPE.PB_MAGAZINE_LANDING_PREVIEW_PAGE]: { chunk: "list", component: "MagazineLandingPage" },
    [PAGE_TYPE.PB_MAGAZINE_ARCHIVE_PREVIEW_PAGE]: { chunk: "list", component: "MagazineArchivePage" },
    [PAGE_TYPE.PB_SEARCH_PREVIEW_PAGE]: { chunk: "list", component: "SearchPage" },
    [PAGE_TYPE.SUBSCRIPTION_SUCCESS]: { chunk: "subscription", component: "SubscriptionSuccess" },
    default: { chunk: "list", component: "NotFoundPage" },
  },
  {
    list: () => import(/* webpackChunkName: "list" */ "./component-bundles/list.js"),
    story: () => import(/* webpackChunkName: "story" */ "./component-bundles/story.js"),
    home: () => import(/* webpackChunkName: "home" */ "./component-bundles/home.js"),
    subscription: () => import(/* webpackChunkName: "subscription" */ "./component-bundles/subscription.js"),
  },
  {
    story: withStoryPageContent,
  }
);

function pickComponentHelper(components, loadChunk, chunkComponentWrapperFns) {
  function pickComponent(pageType, subPageType) {
    const { chunk, component } = components[pageType] || components.default;
    return loadChunk[chunk]()
      .then((bundle) => {
        if (chunkComponentWrapperFns[chunk]) {
          return chunkComponentWrapperFns[chunk](bundle[component], subPageType);
        }
        return bundle[component];
      })
      .then((comp) => comp);
  }

  function getChunkName(pageType) {
    const { chunk } = components[pageType] || components.default;
    return chunk;
  }

  return {
    pickComponent,
    getChunkName,
  };
}
export { pickComponent, getChunkName };
