/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CustomLink } from "../../custom-link";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import "./request-subscription.m.css";
import { SvgHandler } from "../../svg-handler/svg-handler";
import loaderIcon from "../../../../assets/icons/loading.svg";
import { getLoginLink } from "../../../components/sso-components/sso-utils";

export class PaywallBannerBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      successCallout: false,
      errorCallout: false,
    };
  }

  openLogin = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.openLoginTemplate();
  };

  handleSubscriptionClick = async () => {
    if (!this.props.member) {
      this.props.openLoginTemplate();
      return false;
    }
    this.setState({ loader: true });
    const accessPlan = get(this.props, ["accessPlan"], {});
    const { story } = this.props;
    try {
      const { subscription } = await this.props.initRazorPayPayment(
        accessPlan,
        "dynamic_asset",
        story.id,
        story.headline,
        story.slug,
        story['["hero-image-s3-key"]']
      );
      if (subscription) {
        this.setState({ successCallout: true, loader: false });
        setTimeout(() => {
          this.setState({ successCallout: false, loader: false });
          this.props.navigateTo(`/${story.slug}`);
        }, 1000);
        return true;
      }
    } catch {
      this.setState({ errorCallout: true, loader: false, successCallout: false });
      console.log("user closed the payment");
    }
  };

  getLoginRequestBlock = () => {
    const { config } = this.props || {};
    const { enableLocalization = false, localizedElements = {} } = get(
      config,
      ["pagebuilder-config", "general", "localization"],
      {}
    );
    const { loginAccess = {}, signInSignUp = {} } = enableLocalization ? localizedElements : {};
    const { signIn: localizedSignIn } = signInSignUp;
    let { title: localizedTitle = "", description: localizedDescription = "" } = loginAccess;
    const publisherTitle = get(config, ["publisher-settings", "title"], "Ahead");
    const shortCodeMapping = [{ code: "[publisher-title]", value: publisherTitle }];
    const sso = get(config, ["pagebuilder-config", "general", "sso"], {});
    const { bridgeKeeperClientId = "" } = sso;
    shortCodeMapping.map((item) => {
      localizedDescription = localizedDescription.replace(item.code, item.value);
    });

    return (
      <>
        <div styleName="subs-headline">{localizedTitle || "Sign in to get access"}</div>
        <p styleName="subs-desc">
          {localizedDescription ||
            `We're glad you're enjoying ${publisherTitle}. Sign in to continue reading this story.`}
        </p>
        <div styleName="btn-wrapper">
          <button
            styleName="primary-button"
            onClick={(e) => getLoginLink(this.props.member, sso, bridgeKeeperClientId, this.openLogin, e)}
          >
            {localizedSignIn || "Sign In"}
          </button>
        </div>
      </>
    );
  };

  getSubscriptionRequestBlock = () => {
    const { config } = this.props || {};
    const PbAccessTypeConfig = get(config, ["pagebuilder-config", "general", "accesstypeIntegration"]);
    const enablePayPerAsset = get(PbAccessTypeConfig, ["enablePayPerAsset"], false);
    const sso = get(config, ["pagebuilder-config", "general", "sso"], {});
    const { bridgeKeeperClientId = "" } = sso;
    const accessPlan = get(this.props, ["accessPlan"], {});
    const accessPrice = get(accessPlan, ["price_cents"], "") / 100;
    const currencyType = get(accessPlan, ["price_currency"], "");
    const storyAccessLevel = get(this.props, ["story", "access"]);
    if (storyAccessLevel === "login") return this.getLoginRequestBlock();
    const magazineLinked = get(this.props, ["story", "linked-entities"], []).find((item) => item.type === "magazine");
    const magazineSlug = get(magazineLinked, ["slug"], "");
    const { enableLocalization = false, localizedElements = {} } = get(
      config,
      ["pagebuilder-config", "general", "localization"],
      {}
    );
    const {
      signInSignUp = {},
      magazineSubscription = {},
      normalSubscription = {},
      payPerArticle = {},
    } = enableLocalization ? localizedElements : {};
    const { signIn: localizedSignIn } = signInSignUp;
    const { subscribe: localizedMagazineSubscription } = magazineSubscription;
    let localizedTitle, localizedDescription, localizedViewPlans, localizedBuyArticle;
    const { title, description, viewPlans, accountAccess } = normalSubscription;

    const shortCodeMapping = [
      { code: "[currency]", value: currencyType },
      { code: "[price]", value: accessPrice },
    ];

    if (enablePayPerAsset) {
      localizedTitle = payPerArticle.title;
      localizedDescription = payPerArticle.description || "";
      localizedViewPlans = payPerArticle.viewPlans;
      localizedBuyArticle = payPerArticle.buyArticle;
      shortCodeMapping.map((item) => {
        if (item.value) {
          localizedDescription = localizedDescription.replace(item.code, item.value);
        }
      });
    } else {
      localizedTitle = title;
      localizedDescription = description;
      localizedViewPlans = viewPlans;
    }

    const paywallDescription =
      enablePayPerAsset && !isEmpty(accessPlan)
        ? `We’re glad you’re enjoying this story. Buy this article for ${currencyType} ${accessPrice} or subscribe to any of our plans to continue reading the story.`
        : `We’re glad you’re enjoying this story. Subscribe to any of our plans to continue reading the story.`;

    return (
      <>
        <div styleName="subs-headline">{localizedTitle || "Want to read the full story?"}</div>
        <p styleName="subs-desc">{localizedDescription || paywallDescription}</p>
        <div styleName="btn-wrapper">
          {magazineSlug && (
            <div styleName="primary-button magazine-subscription">
              <CustomLink href={`/subscription?group=${magazineSlug}`}>
                {localizedMagazineSubscription || "Magazine Subscription"}
              </CustomLink>
            </div>
          )}
          <div styleName="primary-button">
            <CustomLink href={"/subscription"}>{localizedViewPlans || "View All Plans"}</CustomLink>
          </div>
          {enablePayPerAsset && !isEmpty(accessPlan) && (
            <div
              styleName="buy-button"
              onClick={(e) =>
                getLoginLink(this.props.member, sso, bridgeKeeperClientId, this.handleSubscriptionClick, e)
              }
            >
              {localizedBuyArticle || "Buy this Article"}
            </div>
          )}
        </div>
        {!this.props.member && (
          <span styleName="info-text">
            {accountAccess || "Already have an account?"}{" "}
            <a
              styleName="link"
              onClick={(e) => getLoginLink(this.props.member, sso, bridgeKeeperClientId, this.openLogin, e)}
            >
              {localizedSignIn || "Sign In"}
            </a>
          </span>
        )}
      </>
    );
  };

  handleTryAgain = () => this.setState({ errorCallout: false });

  render() {
    if (this.state.loader) return <LoadingBlock config={this.props.config} />;
    const { errorCallout, successCallout } = this.state;

    return (
      <div className="element-wrapper" id="paywall-banner">
        <div styleName="subs-wrapper">
          {errorCallout && <ErrorBlock onTryAgain={this.handleTryAgain} config={this.props.config} />}
          {successCallout && <ThankyouBlock config={this.props.config} />}
          {!errorCallout && !successCallout && this.getSubscriptionRequestBlock()}
        </div>
      </div>
    );
  }
}

const LoadingBlock = ({ config }) => {
  const { enableLocalization = false, localizedElements = {} } = get(
    config,
    ["pagebuilder-config", "general", "localization"],
    {}
  );
  const { paymentProcessing = {} } = enableLocalization ? localizedElements : {};
  const { title: localizedTitle = "" } = paymentProcessing;
  return (
    <div styleName="block-wrapper">
      <div styleName="loader center-align">
        <p>{localizedTitle || "Payment is processing"}</p>
        <SvgHandler width="50" height="50" clazzName="callout-loader" xlinkHref={loaderIcon} />
      </div>
    </div>
  );
};
LoadingBlock.propTypes = {
  config: PropTypes.shape({
    enableLocalization: PropTypes.bool,
    localizedElements: PropTypes.shape({
      paymentProcessing: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
};

const ThankyouBlock = ({ config }) => {
  const { enableLocalization = false, localizedElements = {} } = get(
    config,
    ["pagebuilder-config", "general", "localization"],
    {}
  );
  const { paymentSuccess = {} } = enableLocalization ? localizedElements : {};
  const { title: localizedTitle = "", description: localizedDescription = "" } = paymentSuccess;

  return (
    <div styleName="block-wrapper">
      <div styleName="subs-headline success-msg-color">{localizedTitle || "Thank you !"}</div>
      <p styleName="subs-desc">
        {localizedDescription || "Your payment was successful. Now you can continue reading the article"}
      </p>
    </div>
  );
};
ThankyouBlock.propTypes = {
  config: PropTypes.shape({
    enableLocalization: PropTypes.bool,
    localizedElements: PropTypes.shape({
      paymentSuccess: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  }),
};

const ErrorBlock = ({ onTryAgain, config }) => {
  const { enableLocalization = false, localizedElements = {} } = get(
    config,
    ["pagebuilder-config", "general", "localization"],
    {}
  );
  const { paymentFailure = {}, buttonLabels = {} } = enableLocalization ? localizedElements : {};
  const { tryAgain: localizedTryAgain = "" } = buttonLabels;
  const { title: localizedTitle = "", description: localizedDescription = "" } = paymentFailure;

  return (
    <div className="element-wrapper">
      <div styleName="subs-wrapper">
        <div styleName="block-wrapper">
          <div styleName="subs-headline error-msg-color">{localizedTitle || "Oops! Something went wrong."}</div>
          <p styleName="subs-desc">{localizedDescription || "Payment was not successful"}</p>
          <button styleName="try-again-btn" onClick={() => onTryAgain()}>
            {localizedTryAgain || "Try again"}
          </button>
        </div>
      </div>
    </div>
  );
};
ErrorBlock.propTypes = {
  onTryAgain: PropTypes.fund,
  config: PropTypes.shape({
    enableLocalization: PropTypes.bool,
    localizedElements: PropTypes.shape({
      tryAgain: PropTypes.string,
      paymentFailure: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  }),
};

PaywallBannerBase.propTypes = {
  member: PropTypes.object,
  openLoginTemplate: PropTypes.func,
  initAccessType: PropTypes.func,
  initRazorPayPayment: PropTypes.func,
  getSubscriptionForUser: PropTypes.func,
  navigateTo: PropTypes.func,
  story: PropTypes.object,
  config: PropTypes.object,
};

const mapStateToProps = (state) => ({
  member: get(state, ["member"], null),
  config: get(state, ["qt", "config"], {}),
  accessPlan: get(state, ["assetPlans", 0], {}),
});

const mapDispatchToProps = (dispatch) => ({
  openLoginTemplate: () => dispatch({ type: "OPEN_LOGIN_TEMPLATE" }),
  navigateTo: (url) => global.app.navigateToPage(dispatch, url),
});

export const PaywallBanner = connect(mapStateToProps, mapDispatchToProps)(PaywallBannerBase);

export default PaywallBanner;
