/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  TAG_PAGE: "tag-page",
  AUTHOR_PAGE: "author-page",
  AUTHORS_PAGE: "authors-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview-page",
  HOME_PREVIEW: "home-preview-page",
  STATIC_PAGE: "static-page",
  COLLECTION_PAGE: "collection-page",
  VISUAL_STORY: "visual-story",
  USER_DETAILS: "user-details",
  USER_LOGIN: "user-login",
  SUBSCRIPTION: "subscription",
  ISSUE_LANDING_PAGE: "issue-landing-page",
  MAGAZINE_LANDING_PAGE: "magazine-landing-page",
  MAGAZINE_ARCHIVE_PAGE: "magazine-archive-page",
  PB_COLLECTION_PREVIEW_PAGE: "pagebuilder-collection-preview-page",
  PB_STORY_PREVIEW_PAGE: "pagebuilder-story-preview-page",
  PB_SECTION_PREVIEW_PAGE: "pagebuilder-section-preview-page",
  PB_AUTHORS_PREVIEW_PAGE: "pagebuilder-authors-preview-page",
  PB_AUTHOR_PREVIEW_PAGE: "pagebuilder-author-preview-page",
  PB_TAG_PREVIEW_PAGE: "pagebuilder-tag-preview-page",
  PB_MAGAZINE_ARCHIVE_PREVIEW_PAGE: "pagebuilder-magazine-archive-preview-page",
  PB_MAGAZINE_LANDING_PREVIEW_PAGE: "pagebuilder-magazine-landing-preview-page",
  PB_MAGAZINE_ISSUE_PREVIEW_PAGE: "pagebuilder-magazine-issue-preview-page",
  PB_SEARCH_PREVIEW_PAGE: "pagebuilder-search-preview-page",
  SUBSCRIPTION_SUCCESS: "subscription-success",
});

export const WHITELIST_CONFIG_KEYS = [
  "cdn-image",
  "sketches-host",
  "polltype-host",
  "publisher-name",
  "publisher-id",
  "env",
  "publisher-settings",
  "social-links",
  "static-pages",
  "layout",
  "sections",
  "public-integrations",
  "language",
  "brightcove",
];

export const DEFAULT_FETCH_PARAM = {
  method: "POST",
  mode: "same-origin",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
  },
};

export const STORY_FIELDS =
  "alternative,slug,metadata,story-template,story-content-id,id,headline,hero-image-s3-key,hero-image-metadata,sections,tags,author-name,author-id,authors,created-at,first-published-at,published-at,last-published-at,url,subheadline,read-time,access,hero-image-caption";

export const SEARCH_AGGREGATION = "story-template,author-name,section-name";

export const LOAD_MORE_LIMIT = 10;

export const DEFAULT_AMAZON_POLLY_CAPTION = "Press play to listen to this story";

export const SLIDER_ANIMATION_DURATION = 1500;

export const ATTACHMENT_TYPE = { PDF: "application/pdf" };

export const SEARCH_RESULTS_LIMIT = 10;

export const SINGLE_LAYER_ROOT_MARGIN = "56px 0px 0px 0px";

export const DOUBLE_LAYER_ROOT_MARGIN = "72px 0px 0px 0px";

export const WHITELIST_MOBILE_CONFIG = (pageType) => {
  switch (pageType) {
    case "config":
      return ["cdn-image"];

    case PAGE_TYPE.HOME_PAGE:
    case PAGE_TYPE.SECTION_PAGE:
    case PAGE_TYPE.COLLECTION_PAGE:
      return {
        collection: ["slug", "name", "summary", "total-count", "items", "metadata"],
        navigationMenu: [
          "menu-group-slug",
          "title",
          "item-type",
          "section-slug",
          "tag-slug",
          "url",
          "children",
          "completeUrl",
          "isExternalLink",
          "section-name",
          "data",
        ],
        section: "all",
      };

    case PAGE_TYPE.STORY_PAGE:
      return {
        relatedStories: "all",
        story: [
          "updated-at",
          "seo",
          "assignee-id",
          "author-name",
          "tags",
          "headline",
          "storyline-id",
          "votes",
          "story-content-id",
          "slug",
          "last-published-at",
          "subheadline",
          "alternative",
          "sections",
          "story-audio",
          "read-time",
          "access-level-value",
          "content-created-at",
          "owner-name",
          "custom-slug",
          "push-notification",
          "publisher-id",
          "hero-image-metadata",
          "comments",
          "word-count",
          "entities",
          "published-at",
          "is-live-blog",
          "breaking-news-linked-story-id",
          "storyline-title",
          "summary",
          "push-notification-title",
          "external-id",
          "canonical-url",
          "is-amp-supported",
          "autotags",
          "linked-entities",
          "status",
          "hero-image-attribution",
          "bullet-type",
          "id",
          "hero-image-s3-key",
          "contributors",
          "associated-series-collection-ids",
          "cards",
          "url",
          "story-version-id",
          "content-type",
          "content-updated-at",
          "author-id",
          "owner-id",
          "linked-stories",
          "linked-story-ids",
          "access",
          "promotional-message",
          "asana-project-id",
          "first-published-at",
          "hero-image-caption",
          "version",
          "story-template",
          "sequence-no",
          "created-at",
          "authors",
          "metadata",
          "publish-at",
          "assignee-name",
        ],
      };

    case PAGE_TYPE.AUTHOR_PAGE:
      return {
        author: ["slug", "name", "avatar-url", "bio", "id", "avatar-s3-key", "twitter-handle", "stats", "metadata"],
        stories: "all",
      };

    case PAGE_TYPE.TAG_PAGE:
      return {
        tag: "all",
        tagName: "all",
        tagDescription: "all",
        tagSlug: "all",
        stories: "all",
      };
  }
};

export const SHOW_MEMBER_METADATA_POPUP = "SHOW_MEMBER_METADATA_POPUP";
export const MEMBER_METADATA_UPDATED = "MEMBER_METADATA_UPDATED";
export const MEMBER_METADATA = "MEMBER_METADATA";

export const headerFive = "header_5";
export const headerSix = "header_6";
export const footerTwo = "footer_2";

/* Custom event */

export const QT_PAGE_DATA = "qt-page-data";
export const defaultPremiumIconColor = "#F7B500";
export const defaultTheme = "#ffffff";
export const defaultDarkTheme = "#1d1d1d";

export const SKIP_CANONICAL = "__SKIP__CANONICAL__";
export const DATA_LIMIT = 160;

export const MADRID_ONLY_PATHS = [
  "/quintype-ace/",
  "/favicon.ico",
  "/qlitics.js",
  "/user-details",
  "/user-login",
  "/subscription",
  "/magazine/",
  "/amp/",
  "/ampstories/",
  "/route-data.json",
  "/mobile-data.json",
  "/subscription-success"
];

export const NEXT_ONLY_PATHS = ["/_next/"];

export const ROUTES_TO_HANDLE = ["*"];
