/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { NewsHeading } from "./news-heading";
import PropTypes from "prop-types";
import get from "lodash/get";
import "./breaking-news.module.css";
import Marquee from "../marquee/src/index";
import { isEmpty } from "../../utils";

class BreakingNewsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNewsId: 0,
    };
  }

  getBreakingNewsTitle() {
    return get(this.props, ["config", "pagebuilder-config", "breakingNews", "title"], "Breaking News");
  }

  render() {
    if (isEmpty(this.props.breakingNews)) {
      return <span />;
    }

    const pageBuilderConfig = get(this.props, ["config", "pagebuilder-config", "breakingNews"], {});
    const pageDirection = get(this.props, ["config", "language", "direction"], "ltr");
    const newsArray = this.props.breakingNews.map((breakingNews, index) => (
      <NewsHeading story={breakingNews} key={breakingNews.id} index={index} />
    ));

    const breakingNewsTemplate = get(pageBuilderConfig, ["template"], "template1");
    const template1 = breakingNewsTemplate === "template1";
    const getBreakingNews = template1 ? "container" : "full-width-container";

    const breakingNewsWrapper = template1 ? "bg-wrapper" : "";

    const scrollSpeed = parseInt(get(pageBuilderConfig, ["scrollSpeed"], 1), 10);
    const delimiter = get(pageBuilderConfig, ["delimiter"], "dot");

    return (
      <div styleName={`${breakingNewsWrapper}`}>
        <div styleName={`breaking-news-collection-wrapper ${getBreakingNews}`} data-breaking-news="true">
          <h2 styleName="title">
            <bdi>{this.getBreakingNewsTitle()}</bdi>
          </h2>
          <div styleName=" content breaking-news-wrapper">
            <Marquee
              loop={true}
              scrollSpeed={scrollSpeed}
              loopData={newsArray}
              delimiter={delimiter}
              pageDirection={pageDirection}
            />
          </div>
        </div>
      </div>
    );
  }
}
BreakingNewsView.propTypes = {
  breakingNews: PropTypes.array,
};

export { BreakingNewsView };
