import { useEffect, useState } from "react";

/**
 * useExternalScript is a custom hook to load a 3rd party script client side
 * it returns the loading state of the script, which can either be "idle", "loading", "ready" or "error"
 * usage: const scriptLoadState = useExternalScript("www.example.com/js-library")
 */
export const useExternalScript = (url) => {
  let [scriptState, setScriptState] = useState(url ? "loading" : "idle");

  useEffect(() => {
    if (!url) {
      setScriptState("idle");
      return;
    }
    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e) => {
      setScriptState(e.type === "load" ? "ready" : "error");
    };

    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    }

    script.addEventListener("load", handleScript);
    script.addEventListener("error", handleScript);

    return () => {
      script.removeEventListener("load", handleScript);
      script.removeEventListener("error", handleScript);
    };
  }, [url]);

  return scriptState;
};
