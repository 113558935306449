/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { MetypeCommentingWidget } from "@metype/components";
import { WithLazy } from "@quintype/components";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FacebookComment } from "./facebook-comment-widget.js";

const generateHostUrl = (story = {}) => {
  if (global.location) {
    return `${global.location.origin}/${story.slug}`;
  }
};

export const CommentingWidget = ({ pbConfig, story = {}, mountAt, lazy = true }) => {
  const { enableComments = false, commentType = "" } = get(pbConfig, ["general", "commentPlugin"], "");
  const {
    host = "",
    accountId = "",
    primaryColor = "",
    fontColor = "",
    metypeIntegrationId: metypeBkIntegrationId = "",
  } = get(pbConfig, ["general", "commentPlugin", "meType"], {});

  const [xIntegrationToken, setXIntegrationToken] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getIntegrationToken();
  }, []);

  const getIntegrationToken = async () => {
    if (!metypeBkIntegrationId) {
      console.log("<---------------", " Metype integration ID unavailable: ", "---------->");
      return false;
    }

    /* Call integrations API to check if user is logged in */
    setLoading(true);
    const accessTokenApi = `${mountAt}/api/auth/v1/access-token/integrations/${metypeBkIntegrationId}`;
    const response = await fetch(accessTokenApi);
    if (response.status === 200) {
      const token = response.headers.get("x-integration-token");
      setXIntegrationToken(token);
      setLoading(false);
      return true;
    }
    setXIntegrationToken("");
    setLoading(false);
    console.log(" Integration error: ", "--------------------------->", response.status);
  };

  function getCommentComponent() {
    if (commentType === "facebook") {
      return <FacebookComment story={story} />;
    }
    return (
      <MetypeCommentingWidget
        host={host}
        accountId={parseInt(accountId)}
        pageURL={generateHostUrl(story)}
        primaryColor={primaryColor}
        fontColor={fontColor}
        jwt={xIntegrationToken}
      />
    );
  }

  if (!enableComments || loading) return null;

  return (
    <>
      {lazy ? (
        <WithLazy margin="0px">
          {() => {
            return getCommentComponent();
          }}
        </WithLazy>
      ) : (
        getCommentComponent()
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pbConfig: get(state, ["qt", "config", "pagebuilder-config"], {}),
    mountAt: get(state, ["qt", "config", "mountAt"], ""),
  };
};

CommentingWidget.propTypes = {
  pbConfig: PropTypes.shape({
    metype: PropTypes.shape({
      host: PropTypes.string,
      accountId: PropTypes.string,
      primaryColor: PropTypes.string,
      fontColor: PropTypes.string,
    }),
  }),
  story: PropTypes.object,
  mountAt: PropTypes.string,
  lazy: PropTypes.bool,
};

export const CommentWidget = connect(mapStateToProps)(CommentingWidget);
