/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, memo, useMemo, useState } from "react";
import {
  PhotoStoryTemplates,
  LiveBlogStoryTemplate,
  TextStoryTemplate,
  ListicleStoryTemplate,
  VideoStoryTemplate,
} from "@quintype/arrow";

import { handleStoryCardsAccess } from "../../utils";
import { getAccessData } from "../sso-components/sso-utils";
import { AccessType, UpdateOnInterval } from "@quintype/components";
import PaywallBanner from "../basic/request-subscription/paywall-banner";
import { getStoryDataWithAdSlots } from "./get-story";
import { StoryFooter } from "../molecules/story-footer";
import { SvgHandler } from "../svg-handler/svg-handler";
import loadingIcon from "../../../assets/icons/loading.svg";
import "./paywall-story-styles.m.css";

const PaywallStoryAccessWrapper = memo(
  ({
    access,
    checkAccess,
    story,
    accessUpdated,
    config,
    initAccessType,
    templateConfig,
    preview,
    storyElementsConfig,
    adComponent,
    widgetComp,
    subPageType,
    initRazorPayPayment,
    enableDarkMode,
    isATGlobalAvailable,
    email,
    phone,
    loadRelatedStories,
  }) => {
    const accessGranted = get(access, ["granted"], null);
    const [accessLoading, setAccessLoding] = useState(accessGranted === null);

    async function getAccess() {
      const accessData = await getAccessData(checkAccess, story, accessUpdated, setAccessLoding, config);
      return accessData;
    }

    useEffect(() => {
      if (!access && isATGlobalAvailable) {
        initAccessType(getAccess);
      }
    }, [isATGlobalAvailable, email, phone]);

    const authorization = useMemo(
      () => handleStoryCardsAccess(access, accessLoading, config),
      [access, accessLoading, config]
    );
    const paywallTemplateConfig = useMemo(
      () =>
        Object.assign({}, templateConfig, {
          noOfVisibleCards: templateConfig.pageBuilderVisibleCards || authorization.visibleCards,
        }),
      [templateConfig, authorization]
    );

    const isInfiniteScrollEnabled = get(templateConfig, ["isInfiniteScrollEnabled"]);

    function visibleCardsRender(visibleCards, firstChild = null) {
      return (
        <>
          <div styleName={accessGranted ? "" : "visible-cards-wrapper"}>{visibleCards}</div>
          {accessLoading ? (
            <div>
              <div styleName="loading-message">Loading content, please wait...</div>
              <div styleName="loader-icon">
                <SvgHandler xlinkHref={loadingIcon} />
              </div>
            </div>
          ) : (
            firstChild
          )}
        </>
      );
    }

    const wrapper =
      (StoryComponent) =>
      ({
        story,
        paywallTemplateConfig,
        storyElementsConfig,
        adComponent,
        widgetComp,
        enableDarkMode,
        loadRelatedStories,
      }) => {
        return (
          <StoryComponent
            story={story}
            accessLoading={accessLoading}
            config={paywallTemplateConfig}
            storyElementsConfig={storyElementsConfig}
            adComponent={adComponent}
            widgetComp={widgetComp}
            enableDarkMode={enableDarkMode}
            loadRelatedStories={loadRelatedStories}
            firstChild={
              authorization.isPaywallEnabled && (
                <PaywallBanner story={story} initRazorPayPayment={initRazorPayPayment} />
              )
            }
            secondChild={!preview && <StoryFooter story={story} isInfiniteScrollEnabled={isInfiniteScrollEnabled} />}
            visibleCardsRender={visibleCardsRender}
          />
        );
      };

    const StoryTemplate = ({
      subPageType,
      story,
      paywallTemplateConfig,
      storyElementsConfig,
      adComponent,
      widgetComp,
      enableDarkMode,
      loadRelatedStories,
    }) => {
      switch (subPageType) {
        case "video":
          return wrapper(VideoStoryTemplate)({
            story,
            paywallTemplateConfig,
            storyElementsConfig,
            adComponent,
            widgetComp,
            enableDarkMode,
            loadRelatedStories,
          });
        case "photo":
          return wrapper(PhotoStoryTemplates)({
            story,
            paywallTemplateConfig,
            storyElementsConfig,
            adComponent,
            widgetComp,
            enableDarkMode,
            loadRelatedStories,
          });
        case "text":
          return wrapper(TextStoryTemplate)({
            story,
            paywallTemplateConfig,
            storyElementsConfig,
            adComponent,
            widgetComp,
            enableDarkMode,
            loadRelatedStories,
          });
        case "listicle":
          return wrapper(ListicleStoryTemplate)({
            story,
            paywallTemplateConfig,
            storyElementsConfig,
            adComponent,
            widgetComp,
            enableDarkMode,
            loadRelatedStories,
          });
        case "live-blog":
          return wrapper(LiveBlogStoryTemplate)({
            story,
            paywallTemplateConfig,
            storyElementsConfig,
            adComponent,
            widgetComp,
            enableDarkMode,
            loadRelatedStories,
          });
        default:
          return wrapper(TextStoryTemplate)({
            story,
            paywallTemplateConfig,
            storyElementsConfig,
            adComponent,
            widgetComp,
            enableDarkMode,
            loadRelatedStories,
          });
      }
    };

    return StoryTemplate({
      subPageType,
      story,
      paywallTemplateConfig,
      storyElementsConfig,
      adComponent,
      widgetComp,
      enableDarkMode,
      loadRelatedStories,
    });
  }
);

PaywallStoryAccessWrapper.propTypes = {
  story: PropTypes.object,
  config: PropTypes.object,
  checkAccess: PropTypes.func,
  accessUpdated: PropTypes.func,
  initAccessType: PropTypes.func,
  accessIsLoading: PropTypes.func,
  initRazorPayPayment: PropTypes.func,
  getSubscriptionForUser: PropTypes.func,
  access: PropTypes.object,
  accessLoading: PropTypes.bool,
  templateConfig: PropTypes.object,
  preview: PropTypes.bool,
  storyElementsConfig: PropTypes.object,
  adComponent: PropTypes.func,
  widgetComp: PropTypes.func,
};

const PaywallStory = ({
  member,
  config,
  story,
  access = {},
  templateConfig,
  storyElementsConfig,
  adComponent,
  widgetComp,
  storyBaseType,
  enableDarkMode,
  loadRelatedStories,
}) => {
  const email = get(member, ["email"], "");
  const phone = get(member, ["metadata", "phone-number"], "");

  const [isATGlobalAvailable, setATGlobal] = useState(false);

  const PbAccessTypeConfig = get(config, ["pagebuilder-config", "general", "accesstypeIntegration"], {});
  const isStaging = get(PbAccessTypeConfig, ["accesstypeMode"]) === "staging";
  const subPageType = storyBaseType || story["story-template"];
  const {
    accesstypeKey = "",
    bridgeKeeperIntegrationId = "",
    enableAccesstype = false,
    disableMetering = true,
    accessTypeProdHost = "",
    accessTypeStgHost = "",
  } = PbAccessTypeConfig;
  const storyId = get(story, ["id"], "");
  const { storySlots } = templateConfig;
  const storyDataLoader = () => getStoryDataWithAdSlots(storyId, storySlots);

  return (
    <AccessType
      enableAccesstype={enableAccesstype}
      isStaging={isStaging}
      accessTypeKey={accesstypeKey} // "9rm1ijqQmGfJ2kwQ9zt2gWhK"
      accessTypeBkIntegrationId={bridgeKeeperIntegrationId}
      email={email}
      phone={phone}
      disableMetering={disableMetering}
      storyId={story.id}
      prodHost={accessTypeProdHost}
      stagingHost={accessTypeStgHost}
      onATGlobalSet={() => {
        setATGlobal(true);
      }}
    >
      {({
        initAccessType,
        checkAccess,
        accessUpdated,
        accessIsLoading,
        initRazorPayPayment,
        getSubscriptionForUser,
      }) => {
        if (subPageType === "live-blog") {
          return (
            <UpdateOnInterval dataLoader={storyDataLoader} interval={20000} initData={{ story: story }}>
              {({ data }) => {
                const { story } = data;
                return (
                  <PaywallStoryAccessWrapper
                    accessIsLoading={accessIsLoading}
                    accessUpdated={accessUpdated}
                    initAccessType={initAccessType}
                    checkAccess={checkAccess}
                    initRazorPayPayment={initRazorPayPayment}
                    getSubscriptionForUser={getSubscriptionForUser}
                    story={story}
                    config={config}
                    access={access}
                    templateConfig={templateConfig}
                    storyElementsConfig={storyElementsConfig}
                    adComponent={adComponent}
                    widgetComp={widgetComp}
                    subPageType={subPageType}
                    enableDarkMode={enableDarkMode}
                    isATGlobalAvailable={isATGlobalAvailable}
                    email={email}
                    phone={phone}
                    loadRelatedStories={loadRelatedStories}
                  />
                );
              }}
            </UpdateOnInterval>
          );
        } else {
          return (
            <PaywallStoryAccessWrapper
              accessIsLoading={accessIsLoading}
              accessUpdated={accessUpdated}
              initAccessType={initAccessType}
              checkAccess={checkAccess}
              initRazorPayPayment={initRazorPayPayment}
              getSubscriptionForUser={getSubscriptionForUser}
              story={story}
              config={config}
              access={access}
              templateConfig={templateConfig}
              storyElementsConfig={storyElementsConfig}
              adComponent={adComponent}
              widgetComp={widgetComp}
              subPageType={subPageType}
              enableDarkMode={enableDarkMode}
              isATGlobalAvailable={isATGlobalAvailable}
              email={email}
              phone={phone}
              loadRelatedStories={loadRelatedStories}
            />
          );
        }
      }}
    </AccessType>
  );
};

PaywallStory.propTypes = {
  story: PropTypes.object,
  config: PropTypes.object,
  member: PropTypes.object,
  access: PropTypes.object,
  templateConfig: PropTypes.object,
  storyElementsConfig: PropTypes.object,
  adComponent: PropTypes.func,
  widgetComp: PropTypes.func,
  storyBaseType: PropTypes.string,
};

export default PaywallStory;
