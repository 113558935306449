/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import "./metered-story-indicator.m.css";
import { CustomLink } from "../../custom-link";
import get from "lodash/get";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SvgHandler } from "../../svg-handler/svg-handler";
import closeIcon from "../../../../assets/icons/social/close.svg";
import { getLoginLink} from "../../../components/sso-components/sso-utils";


export class MeteredStoryIndicatorBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.meteringCount !== prevProps.meteringCount) {
      this.showForm();
    }
  }

  showForm = () => {
    this.setState({ showForm: true });
  };

  hideForm = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ showForm: false });
  };

  openLogin = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.openLoginTemplate();
  };

  render() {
    if (
      (this.props.enableAccesstype && !this.state.showForm) ||
      !this.props.showMeteringIndicator ||
      this.props.meteringCount <= 0
    )
      return null;
    const { buttonLabels = {}, meteredPaywall = {}, signInSignUp = {} } = this.props.localizedElements;
    const { subscribe: localizedSubscribe } = buttonLabels;
    const { signIn: localizedSignIn } = signInSignUp;
    let { description: localizedDescription = "" } = meteredPaywall;
    const shortCodeMapping = [{ code: "[stories-left]", value: this.props.meteringCount }];
    shortCodeMapping.map((item) => {
      localizedDescription = localizedDescription.replace(item.code, item.value);
    });

    const { bridgeKeeperClientId = "" } = this.props.sso;

    return (
      <div>
        <div data-testid="metered-wrapper" styleName="metered-wrapper">
          <p data-testid="metered-desc" styleName="metered-desc">
            {localizedDescription ||
              `You have ${this.props.meteringCount} more free stories left. Subscribe us to continue unlimited access to all
            our stories.`}
          </p>
          <div>
            <div styleName="btn-wrapper">
              <div data-testid="metered-subscribe-btn" styleName="subscribe-btn">
                <CustomLink href={"/subscription"}>{localizedSubscribe || "Subscribe"}</CustomLink>
              </div>
              {!this.props.member && (
                <span data-testid="metered-login" styleName="info-text">
                  Already a user? <a onClick={(e) =>  getLoginLink(this.props.member,this.props.sso,bridgeKeeperClientId, this.openLogin,e)}>{localizedSignIn || "Sign In"}</a>
                </span>
              )}

            </div>
            <div data-testid="metered-close" onClick={this.hideForm}>
              <SvgHandler width="24" height="24" xlinkHref={closeIcon} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MeteredStoryIndicatorBase.propTypes = {
  meteringCount: PropTypes.number,
  member: PropTypes.object,
  openLoginTemplate: PropTypes.func,
  showMeteringIndicator: PropTypes.bool,
  localizedElements: PropTypes.shape({
    meteredPaywall: PropTypes.shape({
      description: PropTypes.string,
    }),
    buttonLabels: PropTypes.shape({
      subscribe: PropTypes.string,
    }),
    signInSignUp: PropTypes.shape({
      signIn: PropTypes.string,
    }),
  }),
  enableAccesstype: PropTypes.bool,
  sso: PropTypes.object,
};

const mapStateToProps = (state) => {
  const pbConfig = get(state, ["qt", "config", "pagebuilder-config", "general"], {});
  const { enableLocalization = false, localizedElements = {} } = pbConfig.localization || {};
  const enableAccesstype = get(pbConfig, ["accesstypeIntegration", "enableAccesstype"], false);
  const sso = get(pbConfig, ["sso"], {});
  return {
    meteringCount: get(state, ["meteringCount"]),
    member: get(state, ["member"], null),
    showMeteringIndicator: get(state, ["showMeteringIndicator"]),
    localizedElements: enableLocalization ? localizedElements : {},
    enableAccesstype,
    sso,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openLoginTemplate: () => dispatch({ type: "OPEN_LOGIN_TEMPLATE" }),
});

export const MeteredStoryIndicator = connect(mapStateToProps, mapDispatchToProps)(MeteredStoryIndicatorBase);
