/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { CustomLink } from "../custom-link";

export function NewsHeading({ story = {}, index }) {
  if (story.metadata["linked-story-slug"]) {
    return (
      <CustomLink id={index} key={story.id} href={"/" + story.metadata["linked-story-slug"]}>
        <article dangerouslySetInnerHTML={{ __html: story.headline }} />
      </CustomLink>
    );
  } else {
    return (
      <article id={index} key={story.id}>
        {story.headline}
      </article>
    );
  }
}
