/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";

async function getAccessData(checkAccess, story, accessUpdated, setAccessLoding, config) {
  const enableAccessType = get(
    config,
    ["pagebuilder-config", "general", "accesstypeIntegration", "enableAccesstype"],
    false
  );
  const storyId = get(story, ["id"]);
  let access = {};

  if (enableAccessType && !checkIfStoryPublic(story)) {
    access = await checkAccess(storyId);
    setAccessLoding(false);
  } else {
    access = generateSubscribedAccess(storyId);
    const accessById = { [storyId]: access };
    if (typeof setAccessLoding === "function" && typeof accessUpdated === "function") {
      setAccessLoding(false);
      accessUpdated(accessById);
    }
  }

  return access;
}

function generateSubscribedAccess(storyId) {
  return { granted: true, grantReason: "SUBSCRIBER", data: {} };
}

function checkIfStoryPublic(story) {
  const access = get(story, ["access"]);
  return access === "public" || access === null;
}

const navigateToAuthDomain = async (bridgeKeeperClientId = "") => {
  if (window && window.location) {
    const { getOauthAuthorizeUrl } = await import("@quintype/bridgekeeper-js");
    const redirectUrl = `${window.location.origin}/api/auth/v1/oauth/token`;
    const oauthAuthorizeUrl = await getOauthAuthorizeUrl(
      bridgeKeeperClientId,
      redirectUrl,
      encodeURIComponent(window.location.href)
    );
    window.location.replace(oauthAuthorizeUrl);
  }
};

const checkIfSingleSignOn = (sso = {}) => {
  const { enableSingleSignOn = "", bridgeKeeperClientId = "" } = sso;
  return bridgeKeeperClientId && enableSingleSignOn;
};

const getLoginLink = (member, sso, bridgeKeeperClientId, openLogin, event) => {
  return (!member || !member["verification-status"]) && checkIfSingleSignOn(sso)
    ? navigateToAuthDomain(bridgeKeeperClientId)
    : openLogin(event);
};

export { getAccessData, checkIfStoryPublic, navigateToAuthDomain, checkIfSingleSignOn, getLoginLink };
