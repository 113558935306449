/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { Component, Suspense } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { SvgHandler } from "../svg-handler/svg-handler";
import { checkIfSingleSignOn } from "./sso-utils";
import loadingIcon from "../../../assets/icons/loading.svg";
import "./sso-dynamic.m.css";

const mapStateToProps = (state) => ({
  member: get(state, ["member"], null),
  isLoginOpen: get(state, ["isLoginOpen"], false),
  config: get(state, ["qt", "config"], {}),
});

const mapDispatchToProps = (dispatch) => ({
  openLoginTemplate: () => dispatch({ type: "OPEN_LOGIN_TEMPLATE" }),
  closeLoginTemplate: () => dispatch({ type: "CLOSE_LOGIN_TEMPLATE" }),
  updateMember: (member) => dispatch({ type: "MEMBER_UPDATED", member }),
});

export class DynamicImportSSO extends Component {
  constructor(props) {
    super(props);
    this.SSOWrapperBase = null;
    this.state = {
      isSSOLoaded: false,
    };
  }

  componentDidUpdate(prevProp) {
    const prevIsLoginOpen = get(prevProp, ["isLoginOpen"], false);
    const currentIsLoginOpen = get(this.props, ["isLoginOpen"], false);

    if (prevIsLoginOpen !== currentIsLoginOpen) {
      setTimeout(() => this.dynamicImport(), 3000);
    }
  }

  dynamicImport() {
    this.SSOWrapperBase = React.lazy(() => import(/* webpackChunkName: "SSOWrapperBase" */ "./sso-wrapper"));
    this.setState({ isSSOLoaded: true });
  }

  fallbackLoader = (isSingleSignOnEnabled) => {
    if (isSingleSignOnEnabled) {
      return <SvgHandler clazzName="loader" xlinkHref={loadingIcon} />;
    } 
      return null;
  };

  render() {
    if (!this.state.isSSOLoaded) return null;
    const { sso } = get(this.props, ["config", "pagebuilder-config", "general"], {});
    const isSingleSignOnEnabled = checkIfSingleSignOn(sso);

    return (
      <div styleName={isSingleSignOnEnabled ? "wrapper" : ""}>
        <Suspense fallback={this.fallbackLoader(isSingleSignOnEnabled)}>
          <this.SSOWrapperBase {...this.props} />
        </Suspense>
      </div>
    );
  }
}

export const SSOWrapper = connect(mapStateToProps, mapDispatchToProps)(DynamicImportSSO);
